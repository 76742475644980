<template>
  <v-card tile class="pa-0">
    <v-card-title class="text-h5 w-full">
      <span class="fontUrbanist primary--text"> {{ title }} </span>
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent="changePassword">
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      :name="old_password_label"
                      rules="required|max:200|min:6"
                      v-slot="{ errors }"
                      slim
                    >
                      <v-text-field
                        v-model="old_password"
                        :label="old_password_label"
                        :type="show1 ? 'text' : 'password'"
                        :append-icon="
                          show1 ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                        "
                        @click:append="show1 = !show1"
                        :error-messages="
                          incorrect_password_msg
                            ? [incorrect_password_msg]
                            : errors
                        "
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      :name="new_password_label"
                      rules="required|max:200|min:6"
                      v-slot="{ errors }"
                      slim
                    >
                      <v-text-field
                        v-model="new_password"
                        :label="new_password_label"
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="
                          show2 ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                        "
                        @click:append="show2 = !show2"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider
                      :name="confirm_password_label"
                      :rules="{
                        required: true,
                        max: 200,
                        min: 6,
                        passwordMatch: new_password,
                      }"
                      v-slot="{ errors }"
                      slim
                    >
                      <v-text-field
                        v-model="confirm_password"
                        :label="confirm_password_label"
                        :type="show3 ? 'text' : 'password'"
                        :append-icon="
                          show3 ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                        "
                        @click:append="show3 = !show3"
                        :error-messages="errors"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="primary"
                      dark
                      @click="changePassword"
                      :loading="loading"
                      :disabled="invalid"
                    >
                      {{ button_label }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import axios from "@/plugins/axios";
import { extend } from "vee-validate";
import { ValidationObserver, ValidationProvider } from "vee-validate";

extend("passwordMatch", {
  validate: (value, { target }) => value === target,
  params: ["target"],
  message: "Passwords must match",
});

export default {
  name: "ChangePasswordModal",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    title: {
      type: String,
      default: "Change Password",
    },
    old_password_label: {
      type: String,
      default: "Old Password",
    },
    new_password_label: {
      type: String,
      default: "New Password",
    },
    confirm_password_label: {
      type: String,
      default: "Confirm Password",
    },
    button_label: {
      type: String,
      default: "Continue",
    },
    password_fail_message: {
      type: String,
      default: "Incorrect password",
    },
    success_message: {
      type: String,
      default: "Your password has been saved successfully",
    },
  },
  data() {
    return {
      old_password: "",
      new_password: "",
      confirm_password: "",
      errors: [],
      loading: false,
      show1: false,
      show2: false,
      show3: false,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      incorrect_password_msg: null,
      passwords_do_not_match_msg: null,
    };
  },
  methods: {
    async changePassword() {
      try {
        this.loading = true;
        this.errors = [];
        this.incorrect_password_msg = null;
        const response = await axios.post(
          `${process.env.VUE_APP_STAGING}/v2/corporate/change_password`,
          {
            old_password: this.old_password,
            new_password: this.new_password,
            confirm_password: this.confirm_password,
          }
        );
        this.$dialog.notify.success(this.success_message);
        this.$emit("submit");
      } catch (error) {
        if (
          error.response.data.error_description === "Old password is incorrect"
        ) {
          this.incorrect_password_msg = this.password_fail_message;
          return;
        }
        if (
          error.response.data.error_description === "Passwords do not match"
        ) {
          this.passwords_do_not_match_msg = this.passwords_not_match_message;
          return;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
